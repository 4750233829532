import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    // 首页
    path: "/",
    name: "index",
    component: index,
  },
  {
    // 删除首页
    path: "/deleteAccountIndex",
    name: "deleteAccountIndex",
    component: () => import("../views/deleteAccountIndex.vue"),
  },
  {
    // 删除详情
    path: "/deleteAccountInfo",
    name: "deleteAccountInfo",
    component: () => import("../views/deleteAccountInfo.vue"),
  },
  {
    // 隐私政策
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/agreements.vue"),
  },
  {
    // 用户协议
    path: "/userPolicy",
    name: "userPolicy",
    component: () => import("../views/userPolicy.vue"),
  },
  {
    // 企业介绍
    path: "/about",
    name: "about",
    component: () => import("../views/about.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
