<template>
  <div class="agreement-main-box">
    <div class="big-title">旗铭玉软件开发</div>
    <div class="agreement-title">【企业介绍】</div>
    <div class="agreement-text">
      旗铭玉保定市软件开发有限公司，坐落于历史悠久的文化名城——保定市，是一家集软件研发、技术咨询、系统集成及解决方案提供为一体的高新技术企业。自成立以来，公司始终秉承“创新引领未来，技术铸就品质”的核心理念，致力于为政府、企业及社会各界提供高效、安全、定制化的软件产品和服务。
    </div>
    <div class="agreement-title">【公司实力】</div>
    <div class="agreement-text">
      旗铭玉汇聚了一支由资深软件工程师、系统架构师、UI/UX设计师及项目管理等专家组成的精英团队。团队成员拥有丰富的行业经验和前瞻性的技术视野，能够紧跟时代步伐，不断探索和应用最新技术，如云计算、大数据、人工智能、区块链、App开发、网站开发等，为客户打造符合行业趋势和市场需求的软件解决方案。
    </div>
    <div class="agreement-title">【企业文化】</div>
    <div class="agreement-text">
      旗铭玉保定市软件开发有限公司注重企业文化建设，倡导“诚信、创新、协作、共赢”的价值观。公司不仅关注员工个人成长，还致力于营造开放、包容的工作氛围，鼓励创新思维和团队协作，共同推动企业发展。
    </div>
    <div class="agreement-title">【展望未来】</div>
    <div class="agreement-text">
      面对未来，旗铭玉保定市软件开发有限公司将继续深耕软件技术领域，紧跟时代潮流，不断探索新技术、新应用，以更加优质的产品和服务回馈社会，携手广大客户共创辉煌未来。我们坚信，通过不懈努力和持续创新，旗铭玉必将在软件行业中脱颖而出，成为引领行业发展的佼佼者。
    </div>
    <div class="agreement-title">【联系方式】</div>
    <div class="agreement-text">邮箱：1942245425@qq.com</div>
    <div class="agreement-text">地址：河北省保定市竞秀区富昌乡西二环3998号橡树创业孵化器</div>
    <div class="del-bottom-text">Copyright © 2023-2024 dosilm All Rights Reserved<br />冀ICP备2024068540号-1</div>
  </div>
</template>
<script>
import { Popup } from "vant";

export default {
  // mixins: [mixindivModule],
  data() {
    return {};
  },
  components: {
    [Popup.name]: Popup,
  },
  created() {
    // this.getIpdatas();
    // this.getWxNews();
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.big-title {
  text-align: center;
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
}
.agreement-main-box {
  padding: 20px 15px;
  width: calc(100% - 30px);
  font-size: 14px;
  color: #2d2d2d;
  text-align: left;
}
.agreement-title {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}
.del-bottom-text {
  color: #2d2d2d;
  font-size: 12px;
  bottom: 5px;
  width: 100%;
  text-align: center;
  padding-top: 40px;
}
.yinsi-bold {
  font-weight: bold;
  padding: 5px 0;
}
</style>
